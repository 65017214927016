/** @format */

import { reducerContentful } from '@/@ducks/contentful/reducer';
import { TReducerContentful } from '@/@ducks/contentful/types';
import { reducerShared } from '@/@ducks/shared/reducer';
import { TReducerShared } from '@/@ducks/shared/types';
import { reducerBooking } from '@/ducks/booking/reducer';
import { TReducerBooking } from '@/ducks/booking/selectors';
import { reducerFilters } from '@/ducks/filters/reducer';
import { TReducerFilters } from '@/ducks/filters/selectors';
import { reducerGeneral } from '@/ducks/general/reducer';
import { TReducerGeneral } from '@/ducks/general/selectors';
import { watcherGeneral } from '@/ducks/general/watcher';
import { reducerListing } from '@/ducks/listing/reducer';
import { TReducerListing } from '@/ducks/listing/types';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

const middlewareSaga = createSagaMiddleware();

const reducers = combineReducers({
  general: reducerGeneral,
  booking: reducerBooking,
  filters: reducerFilters,
  listing: reducerListing,
  shared: reducerShared,
  contentful: reducerContentful,
});

export const configureStore = () => {
  const enhancer = composeWithDevTools({
    // Enable trace
    trace: true,
    traceLimit: 25, // Adjust the trace limit as needed
  })(applyMiddleware(middlewareSaga));
  const store = createStore(reducers, {}, enhancer);
  middlewareSaga.run(watcherGeneral);

  return store;
};

export const store = configureStore();

export type TStore = {
  booking: TReducerBooking;
  general: TReducerGeneral;
  listing: TReducerListing;
  filters: TReducerFilters;
  shared: TReducerShared;
  contentful: TReducerContentful;
};

export type TDispatch = typeof store.dispatch;
// https://react-redux.js.org/using-react-redux/usage-with-typescript#define-root-state-and-dispatch-types
